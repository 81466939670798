import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/i18n/translationHandler'
import store from '@/store'

const load = (component, isView = true) => {
  if(isView)
    return () => import(`@/views/${component}.vue`)
  return () => import(`@/components/${component}.vue`)
}

const routes = [
  {
    path: "/:locale",
    component: load('utils/PassThrough'),
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: load('Dashboard'),
      },
      {
        path: "establishment/:id",
        name: "EstablishmentDetails",
        component: load('EstablishmentDetails'),
      },
      {
        path: "member/:idMember",
        name: "MemberDetails",
        component: load('EstablishmentDetails'),
      },
      {
        path: "members",
        name: "Members",
        component: load('Members'),
        beforeEnter: (to, from, next) => {    
          if(!store.getters['auth/isLoggedIn']) next(from.path === to.path ? '/' : from.path)
          else next()
        },
      },
      {
        path: "hestaprereport",
        name: "hestaprereport",
        component: load('hesta/PreReport'),
        beforeEnter: (to, from, next) => {    
          if(!store.getters['auth/isLoggedIn']) next(from.path === to.path ? '/' : from.path)
          else next()
        },
      },
      {
        path: "memberreport",
        name: "memberreport",
        component: load('members/MembersReport'),
        beforeEnter: (to, from, next) => {    
          if(!store.getters['auth/isLoggedIn']) next(from.path === to.path ? '/' : from.path)
          else next()
        },
      },
      {
        path: "settings",
        name: "Settings",
        component: load('user/Settings'),
        beforeEnter: (to, from, next) => {    
          if(!store.getters['auth/isLoggedIn']) next(from.path === to.path ? '/' : from.path)
          else next()
        },
      },
      {
        path: "admin",
        name: "Admin",
        component: load('user/Admin'),
        beforeEnter: (to, from, next) => {    
          if(!store.getters['auth/isLoggedIn'] || !store.getters['auth/hasUsersRights']) next(from.path === to.path ? '/' : from.path)
          else next()
        },
      },
      {
        path: "login",
        name: "Login",
        component: load('Login/Login'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token) {
            next("/")
          } else {
            next()
          }
        },
      },
      {
        path: "DemandResetPassword",
        name: "DemandResetPassword",
        component: load('Login/DemandResetPassword'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token) {
            next("/")
          } else {
            next()
          }
        },
      },
      {
        path: "account/ResetPassword/:userHash/:token",
        name: "ResetPassword",
        component: load('Login/ResetPassword'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.token) {
            next("/")
          } else {
            next()
          }
        },
      },
    ],
  },
  {
    path: '*',
    redirect: () => Trans.defaultLocale
  },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
