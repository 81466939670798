<template>
  <div>
    <div class="my-2 d-flex align-items-center justify-content-between">
      <b-link :to="$i18nRoute({ name: 'Dashboard'})">
        <font-awesome-icon class="mr-2" icon="long-arrow-alt-left" />
        <span v-html="$t('utils.back')" />
      </b-link>
      <b-button variant="danger" @click="reportError">
        <font-awesome-icon class="mr-2" icon="exclamation-triangle" />
        <span v-html="$t(`${tKey}report`)" />
      </b-button>
    </div>
    <loading v-if="loading" />
    <div id="details" v-else>
      <b-alert class="d-flex align-items-center" variant="warning" :show="currentEstablishment?.qualityFlag != 'ok'">
        <font-awesome-icon class="mr-4" icon="exclamation-triangle" size="2x" />
        <span v-html="$t(`flags.${currentEstablishment.qualityFlag}`)" />
      </b-alert>
      <div class="mb-3">
        <div>
          <div class="d-flex align-items-center justify-content-center pb-3">
            <h2 class="mb-0 pb-0 mr-4" v-html="currentEstablishment.name" />
            <ahv-logo v-if="isMember" :height="32" />
          </div>
          <div v-if="isMember" class="d-flex align-items-center justify-content-center pb-3">
            <font-awesome-icon v-for="i in getNbOfStars(currentEstablishment.stars)" :key="i" class="mx-1 text-custom" icon="star" />
          </div>
          <div class="text-muted d-flex justify-content-center align-items-center">
            <div class="mx-4 text-center" v-if="currentEstablishment.prestationFeatures.some((p) => p.hostingOnly == true)">
              <font-awesome-icon icon="home" size="lg" />
              <p class="small mb-0" v-html="$t(tKey + 'hosting')" />
            </div>
            <div class="mx-4 text-center" v-if="currentEstablishment.prestationFeatures.some((p) => p.hostingOnly == false)">
              <font-awesome-icon icon="utensils" size="lg" />
              <p class="small mb-0" v-html="$t(tKey + 'restoration')" />
            </div>
          </div>  
        </div>
      </div>
      <l-map v-if="center && hasLocation"
        ref="myMap"
        style="height: 300px;"
        :center="center"
        :zoom="zoom"
        :options="mapOptions"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @update:bounds="boundsUpdate"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />        
        <l-circle-marker
          :radius="6"
          :lat-lng="[currentEstablishment?.lat, currentEstablishment?.long]"
          :color="currentEstablishment.idMember ? colors.members : colors.notMembers"
          :fill-color="currentEstablishment.idMember ? colors.members : colors.notMembers"
        />            
      </l-map>      
      <b-card class="mt-3" no-body>
        <b-card-header class="bg-dark text-white m-0 d-flex justify-content-between align-items-center" header-tag="h5">
          <span v-html="$t(`${tKey}establishment.header`)" />
          <font-awesome-icon icon="id-badge" />
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1" v-html="$t(`${tKey}establishment.address`)" />
              <div v-html="`${currentEstablishment.address}`" />
              <div v-html="`${currentEstablishment.npa || ''} ${currentEstablishment.city}`" />
            </b-col>
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}establishment.prestations`)" />
              <div v-for="feature in currentEstablishment.prestationFeatures" :key="feature.label">
                <span v-html="feature.label" />
              </div>
              <div v-if="currentEstablishment.prestationFeatures.length == 0 || currentEstablishment.prestationFeatures == null" 
                v-html="$t(`${tKey}establishment.noData`)"
              />
            </b-col>
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}establishment.size`)" />
              <div>
                <span class="mr-3" v-html="$t(`${tKey}establishment.surface`)" />
                <span v-html="currentEstablishment.surface ? `${currentEstablishment.surface} ${$t(`${tKey}establishment.surfaceUnit`)}` : $t(`${tKey}establishment.noData`)" />
              </div>
              <div>
                <span class="mr-3" v-html="$t(`${tKey}establishment.volume`)" />
                <span v-html="currentEstablishment.volume ? `${currentEstablishment.volume} ${$t(`${tKey}establishment.volumeUnit`)}` : $t(`${tKey}establishment.noData`)" />
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}establishment.constructionYear`)" />
              <div>
                <span v-html="currentEstablishment.constructionYear ? currentEstablishment.constructionYear : $t(`${tKey}establishment.noData`)" />
              </div>
            </b-col>
            <b-col sm="12" md="6" v-if="isMember">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}establishment.capacity`)" />
              <div>
                <font-awesome-icon class="mr-2" icon="door-closed" />
                <span v-html="currentEstablishment.rooms ? `${currentEstablishment.rooms} ${$tc(`${tKey}establishment.rooms`, currentEstablishment.rooms)}` : $t(`${tKey}establishment.noData`)" />
              </div>
              <div>
                <font-awesome-icon class="mr-2" icon="bed" />
                <span v-html="currentEstablishment.beds ? `${currentEstablishment.beds} ${$tc(`${tKey}establishment.beds`, currentEstablishment.beds)}` : $t(`${tKey}establishment.noData`)" />
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card v-if="isMember" class="mt-3" no-body>
        <b-card-header class="bg-dark text-white m-0 d-flex justify-content-between align-items-center" header-tag="h5">
          <span v-html="$t(`${tKey}member.header`)" />
          <font-awesome-icon icon="user" />
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1" v-html="$t(`${tKey}member.section`)" />
              <div v-html="`${currentEstablishment.section}`" />
            </b-col>    
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}member.category`)" />
              <div v-html="`${currentEstablishment.category}`" />
            </b-col>    
            <b-col sm="12" md="6">
              <p class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}member.classification`)" />
              <div v-html="`${currentEstablishment.classification}`" />
            </b-col>            
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="mt-3" no-body>
        <b-card-header class="bg-dark text-white m-0 d-flex justify-content-between align-items-center" header-tag="h5">
          <span v-html="$t(`${tKey}energy.header`)" />
          <font-awesome-icon icon="bolt" />
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="12" md="6">
              <h5 class="font-weight-bold mb-1" v-html="$t(`${tKey}energy.heating`)" />
              <div v-for="feature in currentEstablishment.heatingFeatures.heatingSystems" :key="feature.system">
                <div v-html="`<b>${$t(`${tKey}energy.type`)}</b> : ${feature.system}`" />
                <div v-html="`<b>${$t(`${tKey}energy.source`)}</b> : ${feature.source}`" />
              </div>
            </b-col>     
            <b-col sm="12" md="6">
              <h5 class="font-weight-bold mb-1 mt-sm-3 mt-md-0" v-html="$t(`${tKey}energy.boiler`)" />
              <div v-for="feature in currentEstablishment.heatingFeatures.boilerSystems" :key="feature.system">
                <div v-html="`<b>${$t(`${tKey}energy.type`)}</b> : ${feature.system}`" />
                <div v-html="`<b>${$t(`${tKey}energy.source`)}</b> : ${feature.source}`" />
              </div>
            </b-col>          
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet"
import EstablishmentMixin from '@/mixins/api/establishment';
export default {
    name: 'EstablishmentDetails',
    mixins: [EstablishmentMixin],
    data() {
    return {
      tKey: 'details.',
      zoom: 12,
      center: null,
      bounds: {
        _northEast: latLng(46.92588289494367, 9.014282226562502),
        _southWest: latLng(45.77135470445038, 6.231994628906251)
      },
      colors: {
        members: '#DA291C',
        notMembers: 'cornflowerblue',
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: null,
      currentCenter: null,
      currentBounds: null,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
    }
  },
  computed: {
    isMember() {
      return this.currentEstablishment.idMember != null
    },
    hasLocation() {
      return this.currentEstablishment.lat != null && this.currentEstablishment.long != null
    },
  },
  watch: {
    currentEstablishment(newValue) {
      if (!this.isLoading) this.setCenter(newValue.lat, newValue.long)
    }
  },
  async created() {
    if(this.$route.params.id)
      await this.getEstablishment(this.$route.params.id)
    else
      await this.getMemberEstablishment(this.$route.params.idMember)
  },
  mounted() {
    this.setCenter(this.currentEstablishment.lat, this.currentEstablishment.long)
  },
  methods: {
    getNbOfStars(starString) {
      const regex = /\d+/;
      const match = starString.match(regex);
      return match ? parseInt(match[0], 10) : null;
    },
    reportError() {
      const email = 'info@tourobs.ch'
      const subject = `Plateforme Association Hôtelière - Rapport d'erreur`
      const body = `Bonjour,\n\nJe voudrais vous signaler une erreur pour l'établissement "${this.currentEstablishment.name}" (numéro de contrat : ${this.currentEstablishment.idContract}).\nVoici les informations correctes :\nAdresse exacte :\t\nLocalité :\t\n\nMerci d'avance pour votre aide.\n\n`
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      window.location.href = mailtoLink
    },
    setCenter(lat, long) {
      this.center = latLng(lat, long)
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    boundsUpdate(bounds) {
      this.currentBounds = bounds
    },
  },
}
</script>

<style>

</style>