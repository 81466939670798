<template>
  <b-navbar id="navbar" toggleable="sm" sticky>
    <b-navbar-toggle target="nav-text-collapse" />
    <b-navbar-brand :to="$i18nRoute({ name: 'Dashboard' })">
      <img class="mr-2" :src="require(`@/assets/images/${$t(tKey + 'brandImg')}`)" height="20" />
    </b-navbar-brand>
    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav class="navbar ml-auto">
        <div class="mr-2" v-for="menu in menus" :key="menu.key">
          <div v-if="menu.loggedIn === undefined || menu.loggedIn === isLoggedIn">
            <b-nav-item
              v-if="!menu.options" 
              :to="$i18nRoute({ name: menu.to })"
              @click="menu.click"
            >
              <font-awesome-icon class="mr-1" v-if="menu.icon" :icon="menu.icon" />
              <span v-html="$t(tKey + menu.key)" />
            </b-nav-item>
            <b-nav-item-dropdown v-else right>
              <template #button-content>
                <font-awesome-icon class="mr-2" v-if="menu.icon" :icon="menu.icon" />
                <span v-html="menu.key ? $t(tKey + menu.key) : menu.label" />
              </template>
              <b-dropdown-item
                v-for="option in menu.options" 
                :key="option.key"
                :to="$i18nRoute({ name: option.to })"
                @click="option.click"
              >
                <font-awesome-icon class="mr-2" v-if="option.icon" :icon="option.icon" />
                <span v-html="$t(tKey + option.key)" />
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
        </div>
        <LanguageSwitcher />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher.vue"
import AuthMixin from '@/mixins/api/auth'
export default {
  name: "Index",
  components: {
    LanguageSwitcher
  },
  mixins: [AuthMixin],
  data() {
    return {
      tKey: 'navbar.'
    }
  },
  computed: {
    menus() {
      const menus = [
        {
          key: 'login',
          icon: 'key',
          loggedIn: false,
          to: 'Login',     
        },
        {
          label: this.fullName,
          icon: 'user',
          loggedIn: true,
          options: [
            {
              key: 'settings',
              icon: 'user-cog',
              to: 'Settings'
            },
            {
              key: 'logoff',
              icon: 'sign-out-alt',
              click: () => this.logoutWrapper()
            },
          ]
        },
      ]
      /*if(this.hasUsersRights) 
        menus.push({
          key: 'users',
          icon: 'users',
          loggedIn: true,
          to: 'Admin',
        })*/
      return menus
    },
  },
  methods: {
    logoutWrapper() {
      this.$router.push(this.$i18nRoute({ name: 'Dashboard' }))
      this.logout()
    },
  },
}
</script>

<style lang="scss">
#navbar {
  height: $navbar-height;
  font-size: 0.95em;
  text-align: center;
  background-color: $navbar-bg;
  z-index: 10000;
  border-bottom: 0.3em solid $colorbase;
  width: 100%;

  .nav-text-collapse {
    width: 100%;
  }

  .navbar-nav {
    background-color: $navbar-bg;
  }
}
</style>
