<template>
    <div class="row">
        <div class="col-12">
            <h1>{{ $t('sidebar.memberreport') }}</h1>
        </div>
        <div class="col-12" v-if="reportDetails.workspaceId">
            <PowerBiCard :powerbireport="reportDetails" />
        </div>
    </div>
</template>

<script>
import powerbi from '@/mixins/api/powerbi';
import PowerBiCard from '@/components/dashboard/PowerBiCard.vue';
import { mapGetters } from "vuex";

export default {
    name: "HestaPreReport",
    mixins: [powerbi],
    components: {
        PowerBiCard,
    },
    data() {
        return {
            reportDetails: {
                token: null,
                embedUrl: null,
                reportId: null,
                workspaceId: null,
            }
        }
    },
    async mounted() {
        console.log("starts mounting PowerBi Report");
        await this.GetPowerBiReportId("13cf4057-d1b5-45fb-82de-81c01d6a8bc4");
    },
    computed: {

        ...mapGetters("auth", ["token"]),
    }
}
</script>

<style></style>